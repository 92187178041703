const { TrueorFalse } = require("../dataValidations")

function DeploymentValidation (rows) {
  // Validate headers
  const rowError = validateCsvHeadings(rows[0])
  if (rowError) {
    return `${rowError}`
  }
  // validate entries
  const dataRows = rows.slice(1, rows.length) // ignore header at 0 and get rest of the rows
  for (let i = 0; i < dataRows.length; i++) {
    const rowError = validateCsvRow(dataRows[i])
    if (rowError) {
      return `${rowError} on row ${i + 2}`
    }
  }
}
function validateCsvHeadings (row) {
  if (!row[0] || !/^countryName$/.test(row[0])){
    return "Column 1 heading should be countryName"
  } else if (!row[1] || !/^countryShortName$/.test(row[1])){
    return "Column 2 heading should be countryShortName"
  } else if (!row[2] || !/^phonePrefix$/.test(row[2])){
    return "Column 3 heading should be phonePrefix"
  } else if (!row[3] || !/^timeZone$/.test(row[3])){
    return "Column 4 heading should be timeZone"
  } else if (!row[4] || !/^timeZoneAbbreviation$/.test(row[4])){
    return "Column 5 heading should be timeZoneAbbreviation"
  } else if (!row[5] || !/^timeZoneDetails$/.test(row[5])){
    return "Column 6 heading should be timeZoneDetails"
  } else if (!row[6] || !/^defaultLanguage$/.test(row[6])){
    return "Column 7 heading should be defaultLanguage"
  } else if (!row[7] || !/^allowedLanguages$/.test(row[7])){
    return "Column 8 heading should be allowedLanguages"
  } else if (!row[8] || !/^countryShortCode$/.test(row[8])){
    return "Column 9 heading should be countryShortCode"
  } else if (!row[9] || !/^projectName$/.test(row[9])){
    return "Column 10 heading should be projectName"
  } else if (!row[10] || !/^askForHelpUrl$/.test(row[10])){
    return "Column 11 heading should be askForHelpUrl"
  } else if (!row[11] || !/^isDispensationAllowed$/.test(row[11])){
    return "Column 12 heading should be isDispensationAllowed"
  } else if (!row[12] || !/^enableMERM$/.test(row[12])){
    return "Column 13 heading should be enableMERM"
  } else if (!row[13] || !/^enableVOT$/.test(row[13])){
    return "Column 14 heading should be enableVOT"
  } else if (!row[14] || !/^enable99Dots$/.test(row[14])){
    return "Column 15 heading should be enable99Dots"
  } else if (!row[15] || !/^enable99DotsLite$/.test(row[15])){
    return "Column 16 heading should be enable99DotsLite"
  } else if (!row[16] || !/^enableNone$/.test(row[16])){
    return "Column 17 heading should be enableNone"
  } else if (!row[17] || !/^defaultTech$/.test(row[17])){
    return "Column 18 heading should be defaultTech"
  } else if (!row[18] || !/^clientId$/.test(row[18])){
    return "Column 19 heading should be clientId"
  } else if (!row[19] || !/^diseaseNames$/.test(row[19])){
    return "Column 20 heading should be diseaseNames"
  }
}
function validateCsvRow (row) {
  if (/[,]/g.test(row[0]) || /[,]/g.test(row[1])) {
    return "Remove every single comma ',' and try again"
  } else if (!row[0] || !/^[A-Za-z ]+$/.test(row[0])) {
    return `Column 1 should have a valid countryName`
  } else if (!row[1] || !/^[A-Z]{0,10}$/.test(row[1])) {
    return `Column 2 countryShortName should have upper case letters only and length less than or equal to 10`
  } else if (!row[2] || !/^[0-9]+$/.test(row[2])) {
    return `Column 3 should have a valid phonePrefix`
  } else if (!row[3] || !/^[A-Za-z .]*$/.test(row[3])) {
    return `Column 4 should have a valid timeZone`
  } else if (!row[4] || !/^[A-Z]?[A-Z]+$/.test(row[4])) {
    return `Column 5 should have a valid timeZoneAbbreviation`
  } else if (!row[6] || !/^[A-Za-z]+$/.test(row[6])) {
    return `Column 7 should have a valid defaultLanguage`
  } else if (!row[9] || !/^[A-Za-z0-9]+$/.test(row[9])) {
    return `Column 10 should have a valid projectName`
  } else if (!row[11] || !TrueorFalse(row[11])) {
    return `Column 12 should have a valid isDispensationAllowed`
  } else if (!row[12] || !TrueorFalse(row[12])) {
    return `Column 13 should have a valid enableMERM`
  } else if (!row[13] || !TrueorFalse(row[13])) {
    return `Column 14 should have a valid enableVOT`
  } else if (!row[14] || !TrueorFalse(row[14])) {
    return `Column 15 should have a valid enable99Dots`
  } else if (!row[15] || !TrueorFalse(row[15])) {
    return `Column 16 should have a valid enable99DotsLite`
  } else if (!row[16] || !TrueorFalse(row[16])) {
    return `Column 17 should have a valid enableNone`
  }
}
module.exports = DeploymentValidation
